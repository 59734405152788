body {
    background-color: #293742;
    
}

#header {
    background-color: #394b59;
    box-shadow: 0 0 0 1px rgb(16 22 26 / 20%), 0 0 0 rgb(16 22 26 / 0%), 0 1px 1px rgb(16 22 26 / 40%);
    height: 70px;
    padding: 0 15px;
    position: relative;
    width: 100%;
    z-index: 10;
}

#header #logo {
    width: 270px;
    margin: 0 auto;
}

#header #logo img {
    width: 166px;
    height: 50px;
    float: left;
    /*position: relative;*/
    margin-top: 10px;
}

#header #logo span {
    display: block;
    width: 100px;
    float: left;
    margin-top: 15px;
    /*position: relative;*/
    font-family: sans-serif, Helvetica, Arial;
    font-weight: bold;
    font-size: 2.3em;
    color: #1ED760;
}

#main {
    min-width: 300px;
    max-width: 600px;
    margin: 0 auto;
    /*height: calc(100vh - 120px);
    overflow: hidden;*/
    padding: 20px;
}

#main #page {
    height: 100%;
}

#spotify_login {
    height: 120px;
    padding-top: 20px;
}

#spotify_login img {
    display: block;
    float: left;
    width: 333px;
    height: 100px;
    margin: 0;
}

#spotify_login span {
    display: block;
    float: left;
    font-family: sans-serif, Helvetica, Arial;
    font-weight: bold;
    font-size: 4.6em;
    color: #1ED760;
    padding-top: 10px;
}

.user_logged {
    font-family: sans-serif, Helvetica, Arial;
    font-weight: bold;
    font-size: 3em;
    color: #1ED760;
}

#qr_code {
    width: 300px;
    padding: 25px;
    margin: 50px auto;
    background-color: white;
}

.logout_button {
    float: right;
    width: 120px;
    height: 40px;
}

.sp_img_left {
    float: left;
    width: 64px;
    height: 64px;
}

.sp_current_track {
    padding-left: 65px;
    margin: 10px;
}

.right_button {
    float: right;
    width: 40px;
    height: 40px;
    margin-right: 12px;
    margin-top: 12px;
}